<template>
  <aside>
    <header>
      <h2>Add Payment Method</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main>
      <p>To continue, please add a payment method.</p>

      <payment-method v-model="payment_method" @done="savedCard" />
    </main>
  </aside>
</template>

<script>
export default {
  name: 'SetPaymentMethod',
  props: ['data'],
  data() {
    return {
      payment_method: null
    }
  },
  created() {

  },
  methods: {
    savedCard(action, payment_method) {
      if( action === 'SUCCESS' ) {
        this.payment_method = payment_method;

        this.$emit('done', 'PAYMENT_METHOD_SAVED')
      }
    },
    close() {
      this.$emit('done');
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .payment-method {
  .add-card {
    border: 1px solid rgba(0,0,0,0.05);
  }
}
</style>